html, body iframe,
h1, h2, h3, h4, h5, h6, p, ul, li, html, body , details, summary{
    margin:0;
    padding:0;
    border:0;
    outline:0;
    /* vertical-align:baseline; */
    background:transparent;
    -webkit-font-smoothing: antialiased;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: inherit;
    list-style-type: none;
    list-style:none ;

}

body {
    line-height:1;
    min-height: 100vh; 
    width: 100%;
    /* overflow: hidden; no puede tener overflow porque invalida el position sticky del banner*/ 
}
@media (max-width: 1024px) {
    [data-theme='dark'] body{

        background:var(--background-color);
    }
   
 
   }
header nav::-webkit-scrollbar, header div::-webkit-scrollbar {
    display: none;
}
/* ::-webkit-scrollbar {
   
  } */


* { 
    box-sizing: border-box;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
    text-decoration: none;
   
    display: block;
}

/* .boxBanner {
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

}
.bannerSticky{
    width: 300px;
    height: 600px;
    overflow: hidden;
    background: rgb(249, 249, 249);
    position: sticky;
    top: 50px;
    margin: 0px auto;
} */

/* Relative para Lilax y sus botones */
.module-grid > div{
    position:relative;
}


img{
    width: 100%;
    height: auto;
    object-fit: cover;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow:auto;
    scroll-behavior: smooth;
}

.noscript-img-home{
    display: none;
}

#div-gpt-ad-flotante1{
    position: relative;
    z-index: 1000000;
    height: 0px;
}

:root{
    --ClarinvarVF: "ClarinvarVF";
    --AdelleSansClarin-VF: "AdelleSansClarin-VF";
}

