:root {
  --streetSizeH: 1rem;
  --containerWidth: 1316px;
  --streetSizeV: 1rem;
  --background-color: #ffffff;
}

[data-theme="dark"] {
  --background-color: #212121;
}

.part-hidden {
  overflow: hidden !important;
}

html::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

html::-webkit-scrollbar-track {
  background-color: white;
}

html::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
  height: 20%;
}

html::-webkit-scrollbar-thumb:hover {
  background-color: #a4a4a4;
}

@media (min-width: 1351px) {
  .WIDGET-BOX {
    margin-top: 0 !important;
    padding: 0 !important;
    width: 1316px;
  }
}

@media (min-width: 1025px) and (max-width: 1350px) {
  .WIDGET-BOX {
    width: 980px;
  }
}

@media (max-width: 1024px) {
  .WIDGET-BOX {
    width: 100%;
  }
}

@media (max-width: 980px) {
  :root {
    --streetSizeH: 2rem;
  }
}

@media (max-width: 650px) {
  :root {
    --streetSizeH: 2rem;
  }
}

.CONTAINER-WRAPPER {
  padding: 0 10px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  [data-theme="dark"] .CONTAINER-WRAPPER {
    background: var(--background-color);
    margin-top: -5px;
  }
}

@media (max-width: 975px) {
  .CONTAINER-WRAPPER {
    padding: 0 14px;
  }
}

/* .PUBLISHED .CONTAINER-WRAPPER {
	max-width: calc(var(--containerWidth) + var(--streetSizeH));

}

.PUBLISHED .WIDGET-BOX {
	padding-left: calc(var(--streetSizeH) / 2) !important;
    padding-right: calc(var(--streetSizeH) / 2) !important;
    padding-top: calc(var(--streetSizeV) / 2) !important;
    padding-bottom: calc(var(--streetSizeV) / 2) !important;
} */
