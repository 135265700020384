/*---estilos boton--- */
.btn-default{
    display: flex;
    justify-content: center;
    align-items: center;
    /*---modificar el color de fondo en la hoja de estilo correspondiente--- */
    background: #cc001b;
    font-size: 13px;
    color: white;
    /* font-family: "VelinoSans-Bold"; */
    border-radius: 4px;
    width: 160px;
    height: 30px;
    border: none;
}

/*---estilos boton--- */

/*---estilos expandir imagenes--- */
.picture-open {
    top: 1rem;
    overflow: auto;
    position: absolute;
    left: 1rem;
    

   
  }

  .picture-open .open-image {
    border: none;
    display: flex;
    justify-content: center;
    height: 40px;
    border-radius: 60px;
    width: 40px;
    background: #fff;
    align-items: center;
    cursor: pointer;
   
    
  }

  .picture-open .open-image:before {
    content: "";
    background: url("/img/expand-icon.svg") no-repeat;
    height: 16px;
    width: 16px;
    position: absolute;
  }
/*---estilos expandir imagenes--- */